import React, { useEffect } from "react";
import { getCookie } from "../../shared/helper";

export const impersonate = (userId, column) => {
  const idTokenAdmin = getCookie("idTokenAdmin");
  console.log("type:", column);
  console.log("idTokenAdmin", idTokenAdmin);
  const candidateUrl = process.env.REACT_APP_P3_CANDIDATE_URL;
  const employerUrl = process.env.REACT_APP_P3_EMPLOYER_URL;

  // window.open(
  //   `${column === "Candidate" ? candidateUrl : employerUrl}/auth?userId=${
  //     column === "Candidate" ? 15 : 33
  //   }&token=${adminToken}`,
  //   "_blank"
  // );

  window.open(
    `${
      column === "Candidate" ? candidateUrl : employerUrl
    }/auth?userId=${userId}&token=${idTokenAdmin}`,
    "_blank"
  );
};

const Impersonation = () => {
  useEffect(() => {
    console.log("URL_CAnd", process.env.REACT_APP_P3_CANDIDATE_URL);
  }, []);

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        marginTop: "20%",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <h1>I'm Superadmin #GOD</h1>
      {/* <a
        target="_blank"
        href={`${process.env.REACT_APP_MY_URL}/auth?userId=001&token=${adminToken}`}
      > */}
      <button
        onClick={() => impersonate("001")}
        style={{ width: "10rem", cursor: "pointer", marginTop: "1rem" }}
      >
        Impersonate
      </button>
      {/* </a> */}
    </div>
  );
};

export default Impersonation;
