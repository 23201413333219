import { call, put, takeEvery } from "redux-saga/effects";
import { types } from "../action-types";
import {
  fetchCandiateFailureCampaigns,
  fetchCandiateSuccessCampaigns,
  fetchcsvfileFailure,
  fetchcsvfileSuccess,
  fetchDataFailure,
  fetchDataSuccess,
} from "../actions";
import {
  fetchCampaignsDataApi,
  fetchCSVDataApi,
  fetchDataApi,
} from "../../API/API";
import { alertNotification } from "../../components/notifications/alertNotification";

function* fetchDataSaga(params) {
  try {
    const response = yield call(() => fetchDataApi(params));
    console.log(response);
    yield put(fetchDataSuccess(response));
  } catch (error) {
    yield put(fetchDataFailure(error));
  }
}

function* fetchCandiateCampaignsSaga(params) {
  console.log("response", params.params);
  try {
    const response = yield call(() => fetchCampaignsDataApi(params));
    yield put(fetchCandiateSuccessCampaigns(response));
  } catch (error) {
    yield put(fetchCandiateFailureCampaigns(error));
  }
}

function* fetchCSVfileSaga({ params }) {
  console.log("response", params);
  try {
    const response = yield call(() => fetchCSVDataApi(params));
    if (response.status == 201 || response.status == 200) {
      console.log(response.data, "CSVresponse");
      const element = document.createElement("a");
      element.href = `data:text/csv;charset=utf-8,${escape(response.data)}`;
      element.download = `AllDownload${params.type}.csv`;
      element.click();
      alertNotification("success", "Reports downloaded successfully");
    }
    yield put(fetchcsvfileSuccess(response));
  } catch (error) {
    yield put(fetchcsvfileFailure(error));
  }
}

export function* watchFetchDataSaga() {
  yield takeEvery(types.SEND_REQUEST, fetchDataSaga);
}

export function* watchCandiateCampaignsSaga() {
  yield takeEvery(types.SEARCH_CANDIATE_CAMPAIGNS, fetchCandiateCampaignsSaga);
}

export function* watchfetchCSVfileSaga() {
  yield takeEvery(types.DOWNLOAD_CSV_FILE_INIT, fetchCSVfileSaga);
}
