import { Auth } from "../Config/aws-exports";

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

// export const setCookie = (cookieName, cookieValue, expiryDays) => {
//     const d = new Date(expiryDays ? 864000000000000 : 0);
//     let expires = "expires=" + d.toUTCString();
//     document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
//   };

export const getCookie = (cookieName) => {
  let name = cookieName + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
};

export function checkCookie(cookie) {
  let username = getCookie(cookie);
  if (username != "") {
    alert("Welcome again " + username);
  } else {
    username = prompt("Please enter your name:", "");
    if (username != "" && username != null) {
      setCookie(cookie, username, 365);
    }
  }
}

export const debounce = (func, timeout = 500) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const getFullStateName = (name) => {
  const data = {
    AL: "Alabama",
    AK: "Alaska",
    AZ: "Arizona",
    AR: "Arkansas",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DE: "Delaware",
    DC: "District of Columbia",
    FL: "Florida",
    GA: "Georgia",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NY: "New York",
    NC: "North Carolina",
    ND: "North Dakota",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PA: "Pennsylvania",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VA: "Virginia",
    WA: "Washington",
    WV: "West Virginia",
    WI: "Wisconsin",
    WY: "Wyoming",
    AS: "American Samoa",
    GU: "Guam",
    MH: "Marshall Islands",
    FM: "Micronesia",
    MP: "Northern Marianas",
    PW: "Palau",
    PR: "Puerto Rico",
    VI: "Virgin Islands",
  };
  if (data[name]) {
    return data[name];
  } else {
    return "Texas";
  }
};

export const getStateCode = (name) => {
  const data = {
    Alabama: "AL",
    Alaska: "AK",
    Arizona: "AZ",
    Arkansas: "AR",
    California: "CA",
    Colorado: "CO",
    Connecticut: "CT",
    Delaware: "DE",
    "District of Columbia": "DC",
    Florida: "FL",
    Georgia: "GA",
    Hawaii: "HI",
    Idaho: "ID",
    Illinois: "IL",
    Indiana: "IN",
    Iowa: "IA",
    Kansas: "KS",
    Kentucky: "KY",
    Louisiana: "LA",
    Maine: "ME",
    Maryland: "MD",
    Massachusetts: "MA",
    Michigan: "MI",
    Minnesota: "MN",
    Mississippi: "MS",
    Missouri: "MO",
    Montana: "MT",
    Nebraska: "NE",
    Nevada: "NV",
    "New Hampshire": "NH",
    "New Jersey": "NJ",
    "New Mexico": "NM",
    "New York": "NY",
    "North Carolina": "NC",
    "North Dakota": "ND",
    Ohio: "OH",
    Oklahoma: "OK",
    Oregon: "OR",
    Pennsylvania: "PA",
    "Rhode Island": "RI",
    "South Carolina": "SC",
    "South Dakota": "SD",
    Tennessee: "TN",
    Texas: "TX",
    Utah: "UT",
    Vermont: "VT",
    Virginia: "VA",
    Washington: "WA",
    "West Virginia": "WV",
    Wisconsin: "WI",
    Wyoming: "WY",
    "American Samoa": "AS",
    Guam: "GU",
    "Marshall Islands": "MH",
    Micronesia: "FM",
    "Northern Marianas": "MP",
    Palau: "PW",
    "Puerto Rico": "PR",
    "Virgin Islands": "VI",
    Delhi: "DL",
  };
  return data[name];
};

export const getImageByStateCode = (
  stateCode,
  cityName = "state",
  format = "webp"
) => {
  //const url = `https://res.cloudinary.com/skillgigs/image/upload/q_auto,f_auto,fl_progressive/pwa/Locations/`;

  if (cityName === "state") {
    const url = `https://res.cloudinary.com/skillgigs/image/upload/f_auto,w_500,h_800,c_limit/pwa/Locations/`;
    const fileName = getFullStateName(stateCode);
    return url + fileName?.replace(" ", "_") + ".jpg";
  } else {
    const url = `https://res.cloudinary.com/skillgigs/image/upload/f_auto,w_500,h_800,c_limit/pwa/images/${stateCode.toLowerCase()}/${cityName
      .replace(/ /g, "")
      .toLowerCase()}/1.jpg`;

    return url;
  }
};

export function readableDate(timestamp, secondary = false, locale = "") {
  const d = new Date(timestamp);
  if (locale.length > 0) {
    return d.toLocaleDateString(locale);
  }
  if (!secondary) {
    return d.toLocaleDateString();
  }
  return d.toLocaleDateString() + " " + d.toLocaleTimeString();
}

export function isEmpty(anObject) {
  for (let key in anObject) {
    return false;
  }
  return true;
}

export const onLogout = async () => {
  setCookie("adminToken", "");
  await Auth.signOut();
  window.history.go("/");
};
