import { Input, Tooltip } from "antd";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import caratDownIcon from "../../../assets/icons/Carat_Down_icon.svg";
import { fetchResultsCampaigns } from "../../../redux/actions";
import Loader from "../../Loader/Loader";
import { getCampaignsObject, useBlurEffect } from "./customFunction";

const DropdownFilter = ({
  name,
  allClear,
  datePicker,
  setAllClear,
  setFilterArray,
}) => {
  const [isSelectedCampaign, setIsSelectedCampaign] = useState(false);
  const dispatch = useDispatch();
  const campaignsSearch =
    useSelector((state) => state.fetchDataReducer.campaignsSearch?.data) || [];
  const { loadingFC } = useSelector((state) => state.tableReducer) || "";
  const dropDownRef = useRef(null);
  const fltName = Object.keys(name)[0];
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [filterName, setfilterName] = useState(fltName);
  const payloadApiVariable = [
    "Professioncode",
    "Status",
    "AuthenticationProvider",
    "Source",
    "Campaign",
    "InActive",
  ];
  const getApiVariable = [
    "ProfessionCode",
    "status",
    "AuthenticationProvider",
    "MCSource",
    "MCCampaign",
    "InActive",
  ];

  const handleFilterInput = (DropdownItem, key, fName, e) => {
    const fltName = getApiVariable.includes(fName)
      ? payloadApiVariable[getApiVariable.indexOf(fName)]
      : fName;
    const fltKey = getApiVariable.includes(key)
      ? payloadApiVariable[getApiVariable.indexOf(key)]
      : key;
    e.stopPropagation();
    setfilterName(DropdownItem);
    setDropdownVisible(false);
    setFilterArray((prev) => ({ ...prev, [fltName]: fltKey }));
    setIsSelectedCampaign(false);
  };
  useBlurEffect(dropDownRef, setDropdownVisible);
  useEffect(() => {
    if (allClear) {
      setfilterName(fltName);
      setFilterArray({});
      setIsSelectedCampaign(false);
    }
    return () => {
      setAllClear(false);
    };
  }, [allClear]);

  const handleInput = (e) => {
    const params = e.target.value;
    dispatch(fetchResultsCampaigns({ params, datePicker }));
    setIsSelectedCampaign(true);
  };

  if (isSelectedCampaign)
    name["Campaigns"] = getCampaignsObject(campaignsSearch);
  return (
    <>
      <div className="DropdownFilterContainer">
        <div
          className="selectedDropdownFilter"
          onClick={() => setDropdownVisible(!dropdownVisible)}
        >
          <span>{filterName}</span>
          <img src={caratDownIcon} alt="" />
        </div>
        {dropdownVisible && (
          <div className="optionDropdownFilter" ref={dropDownRef}>
            {fltName === "Campaigns" && (
              <div className="CampaignsSearch">
                <Input
                  type="text"
                  placeholder="Search..."
                  onChange={(e) => handleInput(e)}
                  allowClear
                />
              </div>
            )}
            {loadingFC ? (
              <Loader height={"35px"} size={"35px"} />
            ) : (
              name[fltName]
                ?.slice(0, 10)
                ?.map(({ key, count, filterName }, i) => (
                  <span
                    key={i}
                    onClick={(e) =>
                      handleFilterInput(filterName, key, name["name"], e)
                    }
                  >
                    <Tooltip
                      title={fltName === "Campaigns" ? filterName : ""}
                      placement="right"
                    >
                      <span className={"campaignTitle"}>{filterName}</span>
                    </Tooltip>{" "}
                    <span className="filterCount">({count})</span>
                  </span>
                ))
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default DropdownFilter;
