import { Empty, Skeleton, Table } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import "./CommonTable.css";
import {
  getColumns,
  getColumnsData,
  getSortTableData,
} from "./components/customTableFunction";

const CommonTable = ({ type, setfilterArr }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const {
    loading,
    user: {
      [type]: { data },
    },
  } = useSelector((state) => state.tableReducer) || [];
  const columnsData = getColumnsData(data, type);
  const [userData, setUserData] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    const selectedUserInfo = newSelectedRowKeys.map(
      (selectedUserID) =>
        data?.filter((userInfo) => selectedUserID === userInfo["_id"])[0]
    );
    setfilterArr(selectedUserInfo);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const sortTableData = (tos, title) => {
    const key = title === "Company" ? title + "Name" : "Name";
    setUserData(
      columnsData.sort((a, b) => getSortTableData(a, b, tos, title, key))
    );
  };

  useEffect(() => {
    setUserData(getColumnsData(data, type));
  }, [data]);
  return (
    <>
      <Table
        showSorterTooltip={false}
        rowSelection={rowSelection}
        columns={getColumns(type, sortTableData)}
        dataSource={loading ? [] : userData}
        className="CommonTableContainer"
        bordered
        pagination={false}
        locale={{
          emptyText: loading ? <Skeleton active={true} /> : <Empty />,
        }}
      />
    </>
  );
};

export default CommonTable;
