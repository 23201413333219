import React from "react";
import { Route, Redirect } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import { getCookie } from "../shared/helper";

export const LoginRoute = (props) => {
  const authToken = getCookie("adminToken");
  return !authToken ? (
    <Route {...props} />
  ) : (
    <Layout>
      <Redirect
        to={{
          pathname: "/manageEmployer",
          state: { from: props.location },
        }}
      />
    </Layout>
  );
};

export const ProtectedRoute = (props) => {
  const authToken = getCookie("adminToken");
  return authToken ? (
    <Layout>
      <Route {...props} />
    </Layout>
  ) : (
    <Redirect
      to={{
        pathname: "/",
        state: { from: props.location },
      }}
    />
  );
};
