import React from "react";
import { useRef } from "react";
import {
  getCompanySuggetionArray,
  getHighlightedTextValue,
  getUserSuggestionArray,
  useBlurEffect,
} from "./customFunction";

const SuggestionDropdown = ({
  suggestionData,
  searchQueryState: [searchQuery, setSearchQuery],
  isOpenDropdownState: [isOpenDropdown, setIsOpenDropdown],
  setClearSearch,
  apiCall,
  type,
}) => {
  const user = type === "talent" ? "Candidates" : "Employers";
  const dropdownRef = useRef();
  const userSuggestionArray = getUserSuggestionArray(suggestionData);
  const companySuggetionArray = !(type === "talent")
    ? getCompanySuggetionArray(suggestionData)
    : [];
  useBlurEffect(dropdownRef, setIsOpenDropdown);
  return userSuggestionArray?.length && isOpenDropdown ? (
    <div className="suggestionDropdownConatainer" ref={dropdownRef}>
      <div className="suggestionType">{user}</div>
      <div className="suggestionList">
        {userSuggestionArray?.map((suggestion, i) => (
          <div
            className="suggestionListItem"
            key={i}
            onClick={(e) => {
              e.stopPropagation(e);
              setSearchQuery(suggestion);
              setIsOpenDropdown(false);
              apiCall(suggestion, false, true);
              setClearSearch(true);
            }}
          >
            {getHighlightedTextValue(suggestion, searchQuery)}
          </div>
        ))}
      </div>
      {!(type === "talent") && (
        <>
          <div className="suggestionType mtop7px">Company</div>
          <div className="suggestionList">
            {companySuggetionArray?.map((suggestion, i) => (
              <div
                className="suggestionListItem"
                key={i}
                onClick={(e) => {
                  e.stopPropagation(e);
                  setSearchQuery(suggestion);
                  setIsOpenDropdown(false);
                  apiCall(suggestion);
                  setClearSearch(true);
                }}
              >
                {getHighlightedTextValue(suggestion, searchQuery)}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  ) : null;
};

export default SuggestionDropdown;
