import { useEffect } from "react";

export const Admin_Users = [
  "vjacob@skillgigs.com",
  "abetts@skillgigs.com",
  "mtripathi@skillgigs.com",
];

export const getUserSuggestionArray = (data) =>
  data?.length
    ? data.map(
        (val) =>
          val[Object.keys(val)[1]][Object.keys(val[Object.keys(val)[1]])[0]] +
          " " +
          val[Object.keys(val)[1]][Object.keys(val[Object.keys(val)[1]])[1]]
      )
    : [];

export const getCompanySuggetionArray = (data) =>
  data?.length
    ? data.map(
        (val) =>
          val[Object.keys(val)[1]][Object.keys(val[Object.keys(val)[1]])[2]]
      )
    : [];

export const getCampaignsObject = (data) => {
  return data?.length
    ? data?.map(({ _id, count }) => ({
        key: _id,
        count,
        filterName: _id,
      }))
    : [];
};

export const getFilterKey = (type, countData) => {
  const filter = [
    { Profession: ["HC", "IT"], name: "ProfessionCode" },
    {
      Status:
        type === "talent"
          ? ["Active", "Deactivated", "Deleted"]
          : ["Active", "Inactive", "Pending", "Deleted"],
      name: "status",
    },
    {
      AP: ["EM", "FB", "Google", "LinkedIn", "Apple"],
      name: "AuthenticationProvider",
    },
    {
      // Source: ["EM", "FB", "Google", "LinkedIn", "Apple"],
      Source: [],
      name: "MCSource",
    },
    {
      Campaigns: [],
      name: "MCCampaign",
    },
  ];

  const flnm = ["LinkedIn", "Inactive"];
  const flkey = ["LI", "InActive"];

  let filterArr = filter.map((filterVal, i) => {
    const fltName = Object.keys(filterVal)[0];
    return {
      ...filter[i],
      [fltName]: filterVal[fltName].map((fltItem) => ({
        key: flnm.includes(fltItem) ? flkey[flnm.indexOf(fltItem)] : fltItem,
        count: 0,
        filterName:
          fltName === "Profession"
            ? `${fltItem} - ${
                fltItem === "IT" ? "Information Technology" : "Healthcare"
              }`
            : fltItem,
      })),
    };
  });

  if (countData?.length) {
    let [TotalValue, ...data] = countData;
    let temp = data[0];
    data[0] = data[1];
    data[1] = temp;

    data.forEach((dtItem, dtI) => {
      const dtName = Object.keys(dtItem)[0];
      filterArr.forEach((flItem, flI) => {
        const flName = Object.keys(flItem)[0];
        const flArr = flItem[flName];
        if (flItem["name"] === dtName) {
          const dtSub = dtItem[flItem["name"]];
          dtSub.forEach((dtSubItem, dtSubI) => {
            const dtSubName = Object.keys(dtSubItem)[0];
            const dtSubCount = dtSubItem[dtSubName];
            flArr.forEach((flArrItem, flArrI) => {
              if (flArrItem["key"] === dtSubName) {
                flArrItem["count"] = dtSubCount;
              }
            });
            if (dtName === "MCCampaign" || dtName === "MCSource") {
              flArr.push({
                key: dtSubName,
                count: dtSubCount,
                filterName: dtSubName,
              });
            }
          });
          // if (dtName === "MCCampaign") flArr.shift();
        }
      });
    });
  }
  return type === "talent" ? filterArr : filterArr.slice(0, 2);
};

export const useBlurEffect = (inputRef, callbackFunction) => {
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        callbackFunction(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [inputRef]);
};

export const getHighlightedTextValue = (textValue, searchQuery) => {
  const parts = textValue?.split(new RegExp(`(${searchQuery})`, "gi"));
  return (
    <span>
      {parts?.map((part, i) => (
        <span
          key={i}
          className={
            part?.toLowerCase() === searchQuery?.toLowerCase()
              ? "Highlight_Seacrh_Value"
              : "NotLight_serach_Value"
          }
        >
          {part}
        </span>
      ))}
    </span>
  );
};
