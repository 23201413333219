export const CSVHeaderEmployer =
    [
        { label: "firstname", key: "Profiles.FirstName" },
        { label: "lastname", key: "Profiles.LastName" },
        { label: "companyName", key: "CompanyName" },
        { label: "created", key: "Created" },
        { label: "Active", key: "IsActive" },
        { label: "CallingCode", key: "country_calling_codes" },
        { label: "mobilephone", key: "Profiles.MobilePhone" },
        { label: "profession", key: "Professions.ProfessionCode" },
        { label: "accountemail", key: "AccountEmail[0].EmailAddress" },
        { label: "pictureurl", key: "Profiles.PictureUrl" },
    ]

export const CSVHeaderCandiate =
    [
        { label: "firstname", key: "Profiles.FirstName" },
        { label: "lastname", key: "Profiles.LastName" },
        { label: "CallingCode", key: "country_calling_codes" },
        { label: "mobilephone", key: "Profiles.MobilePhone" },
        { label: "profession", key: "Professions.ProfessionCode" },      
        { label: "cities", key: "Profiles.Cities.CityName" },        
        { label: "countries", key: "Profiles.Countries.FIPS104" },
        { label: "authenticationProviderid", key: "AuthenticationProviderId" },
        { label: "created", key: "Created" },
        { label: "deactivated", key: "Deactivated" },
        { label: "isActive", key: "IsActive" },
        { label: "lastLoginDate", key: "LastLoginDate" },
        { label: "MCCampaigns", key: "MCCampaign" },
        { label: "MCSources", key: "MCSource" }, 
        { label: "accountemail", key: "AccountEmail[0].EmailAddress" },
        { label: "rating", key: "Profiles.Rating" },
        { label: "resumefilename", key: "Profiles.ResumeFileName" },
        { label: "visastatus", key: "Profiles.VisaStatuses.VisaStatusName" },        
        { label: "pictureurl", key: "Profiles.PictureUrl" },
    ]