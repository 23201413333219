import React, { useState } from "react";
import caratDownIcon from "../../../assets/icons/Carat_Down_icon.svg";

const PerPage = ({ selectedPerPage, onChange }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  return (
    <>
      <div className="PerPageContainer">
        <div
          className="selectedPerPage"
          onClick={() => setDropdownVisible(!dropdownVisible)}
        >
          <span>Show {selectedPerPage} results per page</span>
          <img src={caratDownIcon} alt="" />
        </div>
        {dropdownVisible && (
          <div className="optionPerPage">
            {[5, 10, 15, 25, 50, 100].map((perPage, i) => (
              <span
                key={i}
                onClick={() => {
                  onChange(perPage);
                  setDropdownVisible(false);
                }}
              >
                Show {perPage} results per page
              </span>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default PerPage;
