import React from "react";
import "./Header.css";
import Logo from "../../assets/icons/Logo.svg";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import MobileNavMenu from "../Navigation/MobileNavMenu/MobileNavMenu";

const Header = () => {
  const userName = "Administrator";
  const userRole = "Administrator";
  const history = useHistory();
  const [navItem, setNavItem] = useSelector((state) => state.customHookReducer);
  const isCollapse = useSelector((state) => state.collapseReduser.Collapse);

  return (
    <>
      <header
        className={`${
          isCollapse ? "header_container_collapse" : "header_container"
        } `}
      >
        {window.innerWidth < 768 && <MobileNavMenu />}
        {window.innerWidth < 768 && (
          <div
            className="left_header"
            onClick={() => {
              setNavItem(navItem);
              history.push("/");
            }}
          >
            <img src={Logo} alt="logo" className="logo" />
          </div>
        )}
        <div className="right_header">
          <div className="user_profile_pic">
            {/* <img src="" alt="" /> */}
            <span>{userName[0]}</span>
          </div>
          <div className="user_profile_info">
            {/* <span className="user_profile_name">{userName}</span> */}
            <span className="user_profile_role">{userRole}</span>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
