import React, { useEffect } from "react";

import { getCookie } from "../../shared/helper";

const ImpersonateEmployer = () => {
  const adminToken = getCookie("adminToken");

  const impersonateEmployerFunc = (userId) => {
    window.open(
      `${process.env.REACT_APP_P3_EMPLOYER_URL}/auth?userId=${userId}&token=${adminToken}`,
      "_blank"
    );
  };

  useEffect(() => {
    console.log("URL_CAnd", process.env.REACT_APP_P3_EMPLOYER_URL);
  }, []);

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        marginTop: "20%",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <h1>I'm Superadmin #GOD</h1>
      {/* <a
            target="_blank"
            href={`${process.env.REACT_APP_MY_URL}/auth?userId=001&token=${adminToken}`}
          > */}
      <button
        onClick={() => impersonateEmployerFunc("001")}
        style={{ width: "11rem", cursor: "pointer", marginTop: "1rem" }}
      >
        Impersonate Employer
      </button>
      {/* </a> */}
    </div>
  );
};

export default ImpersonateEmployer;
