import React, { useEffect, useRef, useState } from "react";
import "./TableHeaderTitle.css";
import caratDownIcon from "../../../assets/icons/Carat_Down_icon.svg";
import sortUpIcon from "../../../assets/icons/fluent_arrow-sort-up-20-regular.svg";
import sortDownIcon from "../../../assets/icons/fluent_arrow-sort-up-20-regular (1).svg";

const TableHeaderTitle = ({ title, sorting = false, sortTableData }) => {
  const dropDownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const useBlurEffect = (dropDownRef) => {
    useEffect(() => {
      const handleOutsideClick = (e) => {
        if (dropDownRef.current && !dropDownRef.current.contains(e.target)) {
          setIsOpen(false);
        }
      };
      document.addEventListener("mousedown", handleOutsideClick);
      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }, [dropDownRef]);
  };
  useBlurEffect(dropDownRef);
  const handleSorting = (type) => {
    setIsOpen(false);
    sortTableData(type, title);
  };
  return (
    <>
      <div className="TableHeaderTitleContainer">
        <span>{title}</span>
        {sorting && (
          <div className="sortingContainer">
            <img
              src={caratDownIcon}
              alt=""
              onClick={() => setIsOpen(!isOpen)}
            />
            {isOpen && (
              <div className="sortingContent" ref={dropDownRef}>
                <span onClick={() => handleSorting("ascending")}>
                  <img src={sortUpIcon} alt="" />
                  <span>Sort A to Z</span>
                </span>
                <span onClick={() => handleSorting("descending")}>
                  <img src={sortDownIcon} alt="" />
                  <span>Sort Z to A</span>
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default TableHeaderTitle;
