import React, { useRef, useState } from "react";
import DropdownFilter from "./Components/DropdownFilter";
import SearchFilter from "./Components/SearchFilter";
import "./SearchAndFilter.css";
import downloadIcon from "../../assets/icons/Reports.svg";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getParams } from "../Table/components/customTableFunction";
import { fetchTableInit } from "../../redux/actions";
import { useEffect } from "react";
import { getFilterKey } from "./Components/customFunction";
import ResultCountAndDateFilter from "./Components/ResultCountAndDateFilter";
import moment from "moment";
import DownloadButton from "./Components/DownloadButton";
import Loader from "../Loader/Loader";

const SearchAndFilter = ({
  type,
  filterArr,
  handlingPagination: [globelPage, setglobelpage],
}) => {
  const [allClear, setAllClear] = useState(false);
  const [filterArray, setFilterArray] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [datePicker, setDatePicker] = useState({
    StartDate: moment().subtract("days", 30).format("MM-DD-YYYY"),
    EndDate: moment().format("MM-DD-YYYY"),
  });
  const dispatch = useDispatch();
  const handleChildRef = useRef();
  const { countData } =
    useSelector((state) => state.tableReducer.filterCount[type]) || [];
  const { loadingCSV } = useSelector((state) => state.fetchDataReducer) || "";
  const apiCall = (searchInput, isClear, isSuggestiOnClick) => {
    const filter = !isSuggestiOnClick
      ? { ...filterArray, ...datePicker }
      : { ...filterArray };
    const params = getParams(
      {
        type,
        name: searchInput || isClear ? searchInput : searchQuery,
        limit: globelPage["limit"],
      },
      filter,
      true
    );
    dispatch(
      fetchTableInit({
        type,
        params,
      })
    );
    setglobelpage((prev) => ({ ...prev, page: 1 }));
  };
  useEffect(() => apiCall(), [filterArray, datePicker]);
  const filterKey = getFilterKey(type, countData?.length ? countData : []);
  return (
    <>
      <ResultCountAndDateFilter
        totalCount={countData?.length ? countData[0]?.TotalValue[0]?.count : 0}
        datePickerState={[datePicker, setDatePicker]}
        type={type}
      />
      <div className="SearchAndFilterContainer">
        <SearchFilter
          type={type}
          datePicker={datePicker}
          searchQueryState={[searchQuery, setSearchQuery]}
          apiCall={apiCall}
          ref={handleChildRef}
        />
        {filterKey?.map((filterName, i) => (
          <DropdownFilter
            key={i}
            type={type}
            name={filterName}
            allClear={allClear}
            datePicker={datePicker}
            setAllClear={setAllClear}
            setFilterArray={setFilterArray}
          />
        ))}
        <span
          onClick={(e) => {
            e.stopPropagation();
            setSearchQuery("");
            handleChildRef.current.hideCloseIcon();
            setAllClear(true);
          }}
        >
          Clear All
        </span>
        {/* <DownloadButton
          type={type}
          filterArr={filterArr}
          payload={[searchQuery, filterArray, datePicker]}
        >
          {!loadingCSV ? (
            <img src={downloadIcon} alt="" />
          ) : (
            <Loader height={"0px"} size={"35px"} marginAutoLeft />
          )}
        </DownloadButton> */}
      </div>
    </>
  );
};

export default SearchAndFilter;
