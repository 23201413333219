import React, { useEffect, useState } from "react";
import "./NavigationItem.css";
import EmployerIcon from "../../../../assets/icons/Group 27659.svg";
import CandidateIcon from "../../../../assets/icons/Group 27660.svg";
import EmployerIconActive from "../../../../assets/icons/Group1 27338.svg";
import CandidateIconActive from "../../../../assets/icons/group1-27660.svg";
import CollapseIcon from "../../../../assets/icons/octicon_sidebar-collapse-24.svg";
import CollapseIconActive from "../../../../assets/icons/octicon-sidebar-collapse-24.svg";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { defaultNavigation } from "../../../../redux/actions";
import { AiOutlineLeft, AiOutlineLogout, AiOutlineMenu } from "react-icons/ai";
import { onLogout } from "../../../../shared/helper";
import Logo from "../../../../assets/icons/Logo.svg";
import MenuIcon from "../../../../assets/icons/ic-round-menu.svg";
import {
  collapseToggoleFalse,
  collapseToggoleTrue,
} from "../../../../redux/actions";

const NavigationItem = ({ onClose }) => {
  const dispatch = useDispatch();
  // const [isCollapse, setIsCollapse] = useState(false);
  const isCollapse = useSelector((state) => state.collapseReduser.Collapse);
  const [navItemHoverEffect, setNavItemHoverEffect] = useState("");
  const location = useLocation();
  const currentYear = new Date().getFullYear();
  const navInitialData = [
    {
      title: "Manage Employer",
      icon: EmployerIcon,
      iconActive: EmployerIconActive,
      link: "/manageEmployer",
      active: false,
    },
    {
      title: "Manage Candidate",
      icon: CandidateIcon,
      iconActive: CandidateIconActive,
      link: "/manageCandidate",
      active: false,
    },
  ];
  const [navItem, setNavItem] = useState();
  useEffect(() => {
    setNavItem(() =>
      navInitialData.map((item) =>
        location.pathname === item.link
          ? { ...item, active: true }
          : { ...item, active: false }
      )
    );
    dispatch(defaultNavigation([navInitialData, setNavItem]));
  }, [navItemHoverEffect]);

  const Toggle = () => {
    console.log("Toggle");
    if (!isCollapse) {
      console.log("Toggle1");
      dispatch(collapseToggoleFalse(true));
    }
    if (isCollapse) {
      console.log("Toggle2");
      dispatch(collapseToggoleTrue(false));
    }
  };
  return (
    <>
      <div
        className="left_top_header"
        // onClick={() => {
        //   setNavItem(navItem);
        //   history.push("/");
        // }}
      >
        {/* <AiOutlineMenu className="MenuIcon" /> */}

        {window.innerWidth > 768 ? (
          <img
            src={MenuIcon}
            alt=""
            className="MenuIcon"
            // onClick={() => setIsCollapse(!isCollapse)}
            onClick={Toggle}
          />
        ) : (
          <AiOutlineLeft className="leftArrowIcon" onClick={onClose} />
        )}
        <img
          src={Logo}
          alt="logo"
          className={!isCollapse ? "logo" : "collapse-logo"}
        />
      </div>
      {navItem?.map(({ title, icon, iconActive, link, active }, index) => (
        <Link
          className={`NavigationItem ${active ? "navActive" : ""} ${
            isCollapse ? "isCollapse" : ""
          }`}
          to={link}
          key={index}
          onMouseEnter={(e) => {
            setNavItemHoverEffect(title);
          }}
          onMouseLeave={(e) => {
            setNavItemHoverEffect("");
          }}
          onClick={() => {
            setNavItem(
              navItem.map((item, i) =>
                i === index
                  ? { ...item, active: true }
                  : { ...item, active: false }
              )
            );
            window.innerWidth < 768 && onClose();
          }}
        >
          <img
            src={active || title === navItemHoverEffect ? icon : icon}
            alt=""
          />
          {!isCollapse && <div className="navTitle">{title}</div>}
        </Link>
      ))}
      <div
        className={`NavigationItem  NavigationItemLogout ${
          isCollapse ? "isCollapse" : ""
        }`}
        onClick={onLogout}
        onMouseEnter={(e) => {
          setNavItemHoverEffect("Logout");
        }}
        onMouseLeave={(e) => {
          setNavItemHoverEffect("");
        }}
      >
        <AiOutlineLogout
          size={22}
          color={navItemHoverEffect === "Logout" ? "#118936" : "#7e7e7e"}
        />
        {!isCollapse && <div className="navTitle">Logout</div>}
      </div>
      <div
        className={
          !isCollapse ? "copy-rigths-main" : "collaps-copy-rigths-main"
        }
      >
        <span className="copy-rights-content">© {currentYear} SkillGigs</span>
        <span
          className={window.innerWidth < 768 ? "versionformobil" : "version"}
        >
          version 1.0
        </span>
      </div>
    </>
  );
};

export default NavigationItem;
