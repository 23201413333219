import { types } from "../action-types";

const initialState = [];

const customHookReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.DEFAULT_NAVIGATION:
      return action.payload;
    default:
      return state;
  }
};

export default customHookReducer;
