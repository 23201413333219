import { types } from "../action-types";

const initialState = {
  Collapse: false,
};

const collapseReduser = (state = initialState, action) => {
  switch (action.type) {
    case types.COLLAPSE_TOGGLE_TRUE:
      return { ...state, Collapse: false };

    case types.COLLAPSE_TOGGLE_FALSE:
      return { ...state, Collapse: true };

    default:
      return state;
  }
};
export default collapseReduser;
