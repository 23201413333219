import React, { useEffect } from "react";
import "antd/dist/antd.css";
import { AppRouter } from "./routes/router";
import "./assets/css/common.css";
import { useClearCache } from "react-clear-cache";

function App() {
  useEffect(() => {
    (function () {
      window.__insp = window.__insp || [];
      window.__insp.push(["wid", process.env.REACT_APP_INSPECTLET_ID]);
      var ldinsp = function () {
        if (typeof window.__inspld != "undefined") return;
        window.__inspld = 1;
        var insp = document.createElement("script");
        insp.type = "text/javascript";
        insp.async = true;
        insp.id = "inspsync";
        insp.src =
          ("https:" == document.location.protocol ? "https" : "http") +
          `://cdn.inspectlet.com/inspectlet.js?wid=${process.env.REACT_APP_INSPECTLET_ID}&r=` +
          Math.floor(new Date().getTime() / 3600000);
        var x = document.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(insp, x);
      };
      setTimeout(ldinsp, 0);
    })();
  }, []);

  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  console.log(isLatestVersion, "isLatestVersion");
  if (!isLatestVersion) {
    console.log("Updating to latest");
    emptyCacheStorage();
  }

  return (
    <React.Fragment>
      <div className="page_container">
        <AppRouter />
      </div>
    </React.Fragment>
  );
}

export default App;
