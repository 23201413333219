import { types } from "../action-types";

const initialState = {
  loading: false,
  loadingFC: false,
  user: {
    talent: [],
    employer: [],
  },
  filter: {
    talent: {},
    employer: {},
  },
  filterCount: {
    talent: [],
    employer: [],
  },
  error: {},
};

const tableReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_TABLE_INIT:
      return {
        ...state,
        loading: true,
        loadingFC: true,
      };
    case types.FETCH_TABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: {
          ...state.user,
          [action.payload.type]: action.payload.payload,
        },
        error: {},
      };
    case types.FETCH_TABLE_F_SUCCESS:
      return {
        ...state,
        loading: false,
        filter: {
          ...state.filter,
          [action.payload.type]: action.payload.payload,
        },
        error: {},
      };
    case types.FETCH_TABLE_FC_SUCCESS:
      return {
        ...state,
        loadingFC: false,
        filterCount: {
          ...state.filterCount,
          [action.payload.type]: action.payload.payload,
        },
        error: {},
      };
    case types.FETCH_TABLE_FAILURE:
      return {
        ...state,
        loading: false,
        loadingFC: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default tableReducer;
