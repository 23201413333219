import { Avatar, Checkbox } from "antd";
import React, { useState } from "react";
import "./CardDetailed.css";
import shareIcon from "../../assets/icons/bytesize_external.svg";
import { getDateTimeFormat } from "./components/customTableFunction";
import { impersonate } from "../../pages/Auth/Impersonation";

const CardDetailed = ({ data, type, setfilterArr }) => {
  const [handlingBrokenImg, sethandlingBrokenImg] = useState(false);
  return (
    <div
      className="manageProfileCard"
      onClick={() =>
        impersonate(data?._id, type === "talent" ? "Candidate" : "Employer")
      }
    >
      <div className="manageProfileCard-e1">
        <div className="manageProfileCard-e1-Checkbox">
          <Checkbox
            onClick={(e) => {
              e.stopPropagation();
              e.target.checked === true
                ? setfilterArr((prev) => [...prev, data])
                : setfilterArr((prev) =>
                    prev.filter((element) => element._id !== data?._id)
                  );
            }}
          />
        </div>
        <div className="manageProfileCard-e2">
          <div className="manageProfileCard-e2-main">
            <div className="manageProfileCard-e2-image">
              {!handlingBrokenImg && data?.Profiles?.PictureUrl ? (
                <img
                  src={
                    process.env.REACT_APP_S3_URL + data?.Profiles?.PictureUrl
                  }
                  alt=""
                  onError={() => sethandlingBrokenImg(true)}
                />
              ) : (
                <span className="UserCharMob">
                  {data?.Profiles?.FirstName?.slice(0, 1)?.toUpperCase()}
                </span>
              )}
            </div>
            <div className="manageProfileCard-e2-name">
              {data?.Profiles?.FirstName
                ? `${data?.Profiles?.FirstName} ${
                    data?.Profiles?.LastName ? data?.Profiles?.LastName : ""
                  }`
                : data?.UserName
                ? data?.UserName
                : "NA"}
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                impersonate(
                  data?._id,
                  type === "talent" ? "Candidate" : "Employer"
                );
              }}
              className="manageProfileCard-e2-bytesize"
            >
              <img src={shareIcon} alt="" />
            </div>
            <div
              className={`manageProfileCard-e3 ${
                data?.Deactivated
                  ? "Deactivated"
                  : data.IsActive === null || data?.IsActive
                  ? "Active"
                  : "Inactive"
              } `}
            >
              {data?.Deactivated
                ? "Deactivated"
                : data.IsActive === null || data?.IsActive
                ? "Active"
                : "Inactive"}
            </div>
          </div>
          <div className="manageProfileCard-e2-field">
            {type === "talent"
              ? data?.Profiles?.Location && data?.Profiles?.Location["Location"]
                ? data?.Profiles?.Location["Location"]
                : data?.Profiles?.Cities && data?.Profiles?.Cities["CityName"]
                ? `${data?.Profiles?.Cities["CityName"]}${
                    data?.Profiles?.Regions &&
                    data?.Profiles?.Regions["RegionCode"]
                      ? `, ${data?.Profiles?.Regions["RegionCode"]}`
                      : ""
                  }`
                : "NA"
              : data?.Profiles?.CompanyName}{" "}
            | {data?.Professions?.ProfessionCode}
          </div>
          <div className="manageProfileCard-e2-date">
            {type === "talent"
              ? data?.Deactivated
                ? "Deactivated on"
                : data.IsActive === null || data?.IsActive
                ? "Last Login on"
                : "Last Login on"
              : "Created on"}{" "}
            {getDateTimeFormat(data?.Profiles?.created)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardDetailed;
