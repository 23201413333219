import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserProfile } from "../../redux/actions";
import Header from "../Header/Header";
import SideNavigationBar from "../Navigation/SideNavigationBar/SideNavigationBar";

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const isCollapse = useSelector((state) => state.collapseReduser.Collapse);
  useEffect(() => {
    dispatch(fetchUserProfile());
  }, []);
  return (
    <>
      <Header />
      <div className="main_container">
        <SideNavigationBar />
        <div className={isCollapse ? "content-wrap-collapse" : "content_wrap"}>
          {children}
        </div>
      </div>
    </>
  );
};

export default Layout;
