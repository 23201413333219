import { types } from "../action-types";

const initialState = {
  loading: false,
  user: [],
  campaignsSearch: [],
  error: {},
  loadingCSV: false,
  errorCSV: "",
};

const fetchDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SEND_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.SEND_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: {},
      };
    case types.SEND_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        user: {},
        error: action.error,
      };
    case types.DOWNLOAD_CSV_FILE_INIT:
      return {
        ...state,
        loadingCSV: true,
      };
    case types.DOWNLOAD_CSV_FILE_SUCCESS:
      return {
        ...state,
        loadingCSV: false,
      };
    case types.DOWNLOAD_CSV_FILE_FAILURE:
      return {
        ...state,
        loadingCSV: false,
        errorCSV: action.error,
      };
    case types.SEARCH_CANDIATE_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaignsSearch: action.payload,
      };
    default:
      return state;
  }
};

export default fetchDataReducer;
