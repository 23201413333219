import React from "react";
import "./SideNavigationBar.css";
import NavigationItem from "../components/NavigationItem/NavigationItem";

const SideNavigationBar = () => {
  return (
    <>
      <nav className="side_navigation_container">
        <NavigationItem />
      </nav>
    </>
  );
};

export default SideNavigationBar;
