import React from "react";
import { LoadingOutlined } from "@ant-design/icons";

const Loader = ({
  size,
  color,
  height,
  display,
  marginAutoLeft = false,
  marginAutoRight = false,
}) => {
  const ht = height ? height : "15vh";
  const mal = marginAutoLeft ? "auto" : "0px";
  const mar = marginAutoRight ? "auto" : "0px";
  return (
    <div
      style={{
        display: display ? display : "flex",
        justifyContent: "center",
        fontSize: size ? size : "2.5rem",
        margin: `${ht} auto`,
        margin: `${ht} ${mar} ${ht} ${mal}`,
        color: color ? color : "#077cf0",
      }}
    >
      <LoadingOutlined />
    </div>
  );
};

export default Loader;
