import React from "react";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import ManageCandidate from "../pages/Candidates/ManageCandidate";
import ManageEmployer from "../pages/Employers/ManageEmployer";
import AdminLogin from "../pages/Auth/AdminLogin";
import ForgotPassword from "../pages/Auth/components/ForgotPassword";
import { LoginRoute, ProtectedRoute } from "./routes";
// import Impersonation from "../pages/Candidates/Impersonation";
import ImpersonateEmployer from "../pages/Employers/ImpersonateEmployer";

export const AppRouter = () => {
  return (
    <Router>
      <Switch>
        <LoginRoute exact path="/">
          <AdminLogin />
        </LoginRoute>
        <LoginRoute exact path="/login">
          <AdminLogin />
        </LoginRoute>
        <LoginRoute exact path="/forgot">
          <ForgotPassword />
        </LoginRoute>
        <ProtectedRoute exact path="/manageEmployer">
          <ManageEmployer />
        </ProtectedRoute>
        <ProtectedRoute exact path="/manageCandidate">
          <ManageCandidate />
        </ProtectedRoute>
        {/* <ProtectedRoute exact path="/impersonation">
          <Impersonation />
        </ProtectedRoute> */}
        <ProtectedRoute exact path="/impersonation-emp">
          <ImpersonateEmployer />
        </ProtectedRoute>
        <Redirect to="/" />
      </Switch>
    </Router>
  );
};
