import React, { useState, useEffect } from "react";
import "./ForgotPassword.css";
// import "./EmailLogin.css";
import { Form, Input, Button, Checkbox, Tooltip, message } from "antd";
import "antd/dist/antd.css";
import {
  UserOutlined,
  MailOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { Auth } from "../../../Config/aws-exports";
import { FaCheckCircle } from "react-icons/fa";
import Infomessage from "../../../components/Infomessage";
import { fetchExistingtUser } from "../../../redux/actions";
import { connect } from "react-redux";
import GreenCheck from "../../../assets/icons/typcn_tick.svg";
import { GoAlert } from "react-icons/go";
import GreenIcon from "../../../assets/icons/bi_check-circle-fill.svg";

const ForgotPassword = (props) => {
  console.log("====================================");
  console.log("props lostpass", props);
  console.log("====================================");
  const [form] = Form.useForm();
  const [state, setState] = useState("sendmail");
  const [emailid, setEmailid] = useState();
  const [count, setCount] = useState(false);
  const [errormessage, setErrorMessage] = useState("");
  const [messagetype, setMessageType] = useState("container-error");
  const [checkToggel, setCheckToggel] = useState(false);
  const [showCheckIconEmail, setShowCheckIconEmail] = useState(false);
  const [greenCheck, setGreenCheck] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const textemail = <span>example: example@mail.com</span>;
  const loginPage = () => {
    history.push("/login");
  };
  const textpass = <span>Minimum 8 characters required</span>;

  useEffect(() => {
    setCount(false);
    const checkUser = props.user.userExist;
    if (
      checkUser &&
      checkUser.valid &&
      checkUser.cognitoTriggerSource !== "PreSignUp_ExternalProvider"
    ) {
      setState("mailSent");
      onForgot(emailid);
    }
    if (emailid && !checkUser.valid) {
      // console.log(checkUser.msg);
      console.log("User with current email doesn't exist");
      setErrorMessage("The email address you entered could not be found");
      setCount(true);
      setGreenCheck(false);
      // setTimeout(() => {
      //   setCount(false);
      //   setGreenCheck(true)
      // }, 4000);
    }
    if (
      emailid &&
      checkUser.cognitoTriggerSource === "PreSignUp_ExternalProvider"
    ) {
      setMessageType("container-error");
      setErrorMessage("OOPS, can't reset password for social login");
      setCount(true);
      setGreenCheck(false);
      // setTimeout(() => {
      //   setCount(false);
      // }, 400000);
    }
    // setState(data);
  }, [props.user.userExist]);
  useEffect(() => {
    setState("sendmail");
  }, [props.backClick]);

  const checkUserEmail = ({ email }) => {
    setEmailid(email);
    if (email) {
      props.checkUser(email);
    }
  };

  const onForgot = async (email) => {
    // // console.log("Received values of form: ", values);
    //   // Send confirmation code to user's email
    Auth.forgotPassword(email)
      .then((data) => console.log(data))
      .catch((err) => console.log(err));

    // Auth.confirmSignUp({Username: email, ConfirmationCode: "12123154"})
    // .then((data) => console.log(data))
    // .catch((err) => console.log(err));
  };
  const onFinish = async (event) => {
    setCount(false);
    // Collect confirmation code and new password, then
    const username = emailid;
    if (event.confirm_password !== event.new_password) {
      setCount(true);
      setMessageType("container-password-error");
      setErrorMessage("Please make sure your passwords match");
      setTimeout(() => {
        setCount(false);
      }, 4000);
    } else {
      Auth.forgotPasswordSubmit(username, event.code, event.new_password)
        .then((data) => {
          console.log("data :", data);
          setMessageType("container-success");
          setCount(true);
          setErrorMessage("Password Changed Successfully!");
          setTimeout(() => {
            history.push("/login");
            setState("sendmail");
            form.resetFields();
            setCount(false);
          }, 3000);
          // history.push("/login");
          props.lostPassToLogin("Success");
        })
        .catch((err) => {
          console.log("data error", err);
          console.log("data error", err.message);
          setCount(true);
          // setErrorMessage(err.message);
          setTimeout(() => {
            setCount(false);
            form.resetFields();
          }, 3000);
        });
    }
  };

  const validateEmail = (e) => {
    setCount(false);
    const email = e.target.value;
    const valid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.length >= 0) {
      setShowCheckIconEmail(true);
    }
    if (valid.test(email)) {
      setGreenCheck(true);
    } else {
      setGreenCheck(false);
    }
  };

  if (state === "sendmail") {
    return (
      <div className="container">
        <div className="head-text">Reset Password</div>
        <div className="lpass-box-text">
          Just enter your email address below and we'll <br /> send you
          verification code on your email to reset <br /> password
        </div>
        <Form
          form={form}
          name="normal_login"
          className="login-form-lpass"
          initialValues={{
            remember: false,
          }}
          // onFinish={onForgot}
          onFinish={checkUserEmail}
        >
          {/* <div className="form-label">Email</div> */}
          <Tooltip placement="topRight" title={textemail} trigger="click">
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The email address you entered could not be found",
                },
                {
                  required: true,
                  message: "Please enter user name",
                },
              ]}
            >
              <Input
                // className={ greenCheck === true ? "valiDateInput"  : !greenCheck ? "notvaliDateInput" ? greenCheck==null : null }
                className={
                  greenCheck ? "valiDateInput" : count ? "notvaliDateInput" : ""
              }
                placeholder="Enter your email"
                onChange={(e) => {
                  validateEmail(e);
                }}
                suffix={
                  greenCheck ? (
                    <>
                      {!loading ? (
                        <img src={GreenCheck} alt="greenCheck" />
                      ) : null}
                    </>
                  ) : (
                    <>
                      {showCheckIconEmail ? (
                        <>
                          {!loading ? (
                            <GoAlert
                              style={{
                                color: "#db2828",
                                marginRight: "0.4rem",
                              }}
                            />
                          ) : null}
                        </>
                      ) : null}
                    </>
                  )
                }
              />
            </Form.Item>
          </Tooltip>
          {count && <Infomessage message={errormessage} class={messagetype} />}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button-lpass"
              // onClick={mailSent}
              onClick={()=>{
                if(!greenCheck){
                  setShowCheckIconEmail(true)
                }
              }}
            >
              Send Verification Code
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  } else {
    return (
      <div className="container-1">
        <div className="head-text">Reset Password</div>
        {/* <div className="lpass-box-text">
          Just enter your email address below and we'll send you instructions on
          how to reset your password
        </div> */}
        <Form
          name="normal_login"
          className="login-form-lpass"
          initialValues={{
            remember: false,
          }}
          onFinish={onFinish}
        >
          <div className="lpass2-span">
            {/* <FaCheckCircle className="lpass-checkbox" /> */}
            <img className="lpass2-span-img" src={GreenIcon} alt="" />
            <div className="lpass2-span-text">
              <b>Success</b> Password reset instructions have
              <br />
              been sent to {emailid}
            </div>
            {/* <div className="Email_Send_text">
            <p>
              If the email doesn’t show up ,Check your spam folder. We sent it
              from <span>help@skillgigs.com</span>{" "}
            </p>
          </div> */}
          </div>
          <div className="Email_Send_text">
            <p>
              If the email doesn’t show up ,Check your spam folder. We sent it
              from <span>help@skillgigs.com</span>{" "}
            </p>
          </div>
          {count && <Infomessage message={errormessage} class={messagetype} />}

          <div className="form-label">Code</div>

          <Form.Item
            name="code"
            className="new_pass_input"
            rules={[
              {
                // pattern: /^([0-9]{1,4})*$/,
                pattern: /^\d{6}$/,
                message: "Enter valid code",
              },
              {
                required: true,
                message: "Please input code",
              },
            ]}
          >
            <Input
              // type="number"
              maxLength="6"
              autoComplete="new-password"
              placeholder="OTP code"
            />
          </Form.Item>

          <div className="form-label">New password</div>

          <Tooltip placement="topRight" title={textpass} trigger="click">
            <Form.Item
              name="new_password"
              className="new_pass_input"
              rules={[
                {
                  // type: "regexp",
                  pattern: /^\S{8,}$/,
                  message: "The input is not a valid Password",
                },
                {
                  required: true,
                  message: "Please input new password",
                },
              ]}
            >
              <Input.Password
                placeholder="New password"
                autoComplete="new-password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
          </Tooltip>

          <div className="form-label">Confirm password</div>
          <Form.Item
            name="confirm_password"
            className="new_pass_input"
            rules={[
              {
                required: true,
                message: "Please confirm new password!",
              },
            ]}
          >
            <Input placeholder="Confirm password" />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button-lpass"
              // onClick={mailSent}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
        {/* <div className="email_id_field">
          <input value={emailid} className="input_feild" />
        </div> */}
        {/* <div className="lpass2-span">
          <FaCheckCircle className="lpass-checkbox" />
          <div className="lpass2-span-text">
            <b>Success</b> Password reset instructions have
            <br />
            been sent to {emailid}
          </div>
          <div className="Email_Send_text">
            <p>
              If the email doesn’t show up ,Check your spam folder. We sent it
              from <span>help@skillgigs.com</span>{" "}
            </p>
          </div>
        </div> */}
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    user: state.chekckUserReducer.user,
    error: state.chekckUserReducer,
    // userProfile: state.profileReducer.user.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkUser: (params) => {
      dispatch(fetchExistingtUser(params)); // action dispatched
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
