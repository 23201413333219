import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Row,
  Select,
  Drawer,
  Input,
  Dropdown,
  Menu,
  AutoComplete,
} from "antd";
import "./SearchHeader.css";
import "antd/dist/antd.css";
import {
  CalendarFilled,
  CloseOutlined,
  DownloadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import filterIcon from "../../assets/icons/Group 21390226.svg";
import filterIconGreen from "../../assets/icons/Group 21390247.svg";
import downloadIcon from "../../assets/icons/Search.svg";
import { DatePicker } from "antd";
import SearchRadioButton, {
  SearchRadioButtonCampaigns,
} from "./SearchRadioButton";
import { useDispatch, useSelector } from "react-redux";
import DatePickerCalendra from "../../assets/icons/calenderIcon.svg";
import {
  fetchCandiateResults,
  fetchcsvfile,
  fetchDataFake,
  fetchResultsCampaigns,
  fetchTableInit,
} from "../../redux/actions";
import {
  getParams,
  removeEmpty,
} from "../Table/components/customTableFunction";
import moment from "moment";
import { CSVDownload, CSVLink } from "react-csv";
import {
  CSVHeaderCandiate,
  CSVHeaderEmployer,
} from "./CSV_Header/CSVHeaderFile";
import {
  getCompanySuggetionArray,
  getUserSuggestionArray,
  Admin_Users,
} from "../SearchAndFilter/Components/customFunction";
import Loader from "../Loader/Loader";
import { alertNotification } from "../notifications/alertNotification";
// import { SendData } from '../Table/CardDetailed';

const { RangePicker } = DatePicker;

const SearchHeader = (props) => {
  const [open, setOpen] = useState(false);
  const [Status, setStatus] = useState(null);
  const [CandidatesArrStatus, setCandidatesArrStatus] = useState([
    "Active",
    "Deactivated",
    "Deleted",
  ]);
  const [AuthendicationArrStatus, setAuthendicationArrStatus] = useState([
    "EM",
    "FB",
    "Google",
    "LI",
    "AP",
  ]);
  const [Campaigns, setCampaigns] = useState([]);
  const [onClick, setOnClick] = useState(false);
  const [AuthValue, setAuthValue] = useState(null);
  const [SourceValue, setSourceValue] = useState(null);
  const [CampaignsValue, setCampaignsvalue] = useState(null);
  const [DownloadValue, setDownloadValue] = useState(null);
  const [SearchQuery, setSearchQuery] = useState("");
  const [visible, setVisible] = useState(false);
  const [inputwidth, setinputWidth] = useState(180);
  const [Profession, setProfession] = useState(null);
  const {
    ManageCandidates,
    ManageEmployer,
    setSearchResult,
    handlingPagination: [globelPage, setglobelpage],
    filterArr,
  } = props;
  const [SearchManage, setSearchManage] = useState(
    ManageCandidates ? "suggestionTalent" : "suggestionEmp"
  );
  const [filterManage, setfilterManage] = useState(
    ManageCandidates ? "talent" : "employer"
  );
  const [CSVHeader, setCSVHeader] = useState(
    ManageCandidates ? CSVHeaderCandiate : CSVHeaderEmployer
  );
  const [isDateChange, setIsDateChange] = useState({
    onChangeDate: false,
    onApplyFilter: false,
  });
  const [SearchSuggestionsArr, setSearchSuggestionsArr] = useState([]);
  const [FilterCount, setFilterCount] = useState(0);
  const [campaignsSearch, setcampaignsSearch] = useState();
  const [datePicker, setDatePicker] = useState({
    StartDate: moment().subtract("days", 30).format("MM-DD-YYYY"),
    EndDate: moment().format("MM-DD-YYYY"),
  });
  const { loadingCSV } = useSelector((state) => state.fetchDataReducer) || "";
  const { loadingFC } = useSelector((state) => state.tableReducer) || "";
  const dateFormat = "MMM D, YYYY";
  const CurrentDate = moment(datePicker["EndDate"]);
  const startdate = moment(datePicker["StartDate"]);
  console.log(CurrentDate, startdate, "startdate");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.profileReducer.success) || [];

  const handlingDisableDate = (current) => {
    if (Admin_Users.includes(user?.EmailAddress)) return false;
    else return current > moment() || current < moment().subtract(3, "months");
  };

  const handleDataPicker = (e) => {
    e !== null &&
      setDatePicker({
        StartDate: moment(e[0]).format("MM-DD-YYYY"),
        EndDate: moment(e[1]).format("MM-DD-YYYY"),
      });
    setIsDateChange((prev) => ({ ...prev, onChangeDate: true }));
  };

  const handleAllDownloadCsv = () => {
    const payload = {
      ...removeEmpty(filter),
      ...datePicker,
      name: SearchQuery,
      downloadType: "all",
    };
    dispatch(fetchcsvfile({ type: filterManage, payload }));
  };

  const SearchUserData = useSelector((state) => state.fetchDataReducer.user);
  const Searchcampaigns = useSelector(
    (state) => state.fetchDataReducer.campaignsSearch
  );

  const { data } =
    useSelector((state) => state.tableReducer.user[filterManage]) || [];
  const { countData } =
    useSelector((state) => state.tableReducer.filterCount[filterManage]) || [];
  console.log(
    filterManage,
    globelPage,
    "filterManage",
    data,
    Searchcampaigns?.data
  );
  // console.log("data  -- "+JSON.stringify(data))
  console.log("countData  -- " + JSON.stringify(countData));

  // http://p3adminbackend-environment.eba-xkv5skx2.us-east-2.elasticbeanstalk.com/talent?Professioncode=HC&Status=Deactivated&AuthenticationProvider=LI&Source=EM&Campaign=Talentsignup&page=1&limit=10

  // http://p3adminbackend-environment.eba-xkv5skx2.us-east-2.elasticbeanstalk.com/talent?Professioncode=HC&StartDate=05-17-2022&EndDate=11-18-2022&page=1&limit=10
  let filter = {
    Professioncode: Profession,
    Source: SourceValue,
    AuthenticationProvider: AuthValue,
    Campaign: CampaignsValue,
    Status: Status,
  };

  const SelectNameFilter = (name) => {
    setSearchQuery(name);
    setVisible(false);
    const params = getParams(
      {
        // ...datePicker,
        type: filterManage,
        name: name,
        limit: globelPage?.limit,
        page: globelPage?.page,
      },
      filter,
      "isFilter"
    );
    dispatch(
      fetchTableInit({
        type: filterManage,
        params,
      })
    );
  };

  const ApplyFilter = (isNull = false) => {
    const DatePicker = isNull ? { StartDate: null, EndDate: null } : datePicker;
    const params = getParams(
      {
        // ...datePicker,
        type: filterManage,
        name: SearchQuery,
        page: 1,
        limit: 15,
      },
      { ...filter, ...DatePicker },
      "isFilter"
    );
    dispatch(
      fetchTableInit({
        type: filterManage,
        params,
      })
    );
    setOpen(false);
    setFilterCount(Object.keys(removeEmpty(filter)).length);
    console.log(
      "Status",
      Status,
      Profession,
      DownloadValue,
      CampaignsValue,
      SourceValue,
      AuthValue,
      Object.keys(removeEmpty(filter)).length
    );
    setglobelpage({ page: 1, limit: 15 });
    isDateChange["onChangeDate"] &&
      setIsDateChange((prev) => ({ ...prev, onApplyFilter: true }));
  };

  // const filterfunction = (data) => {
  //     const r = data.filter((elem) => { if (SendData().find((id) => elem._id === id)) return elem });
  //     return r
  // }
  console.log(data, "filterdatafunction", filterArr?.length);
  const ClearFilter = () => {
    setOpen(false);
    setProfession(null);
    setStatus(null);
    setCampaignsvalue(null);
    setSourceValue(null);
    setAuthValue(null);
    setFilterCount(0);
    setSearchQuery("");
    let params = getParams({
      type: filterManage,
      name: "",
      page: 1,
      limit: 10,
      ...datePicker,
    });
    dispatch(
      fetchTableInit({
        type: filterManage,
        params,
      })
    );
  };

  const SearchQueryFunction = (e) => {
    setSearchQuery(e.target.value);
    setOnClick(true);
  };

  const showSuggestion = () => {
    let visibledata = SearchQuery?.length;

    dispatch(fetchDataFake({ SearchManage, SearchQuery }));
    if (visibledata > 0) {
      setVisible(true);
    } else {
      setVisible(false);
    }
    if (visibledata < 1) {
      setinputWidth(160);
    }
    if (visibledata <= 22) {
      setinputWidth(100 + SearchQuery?.length * 5);
    } else if (visibledata > 22) {
      setinputWidth(100 + SearchQuery?.length * 6.6);
    }
    console.log("helloSearchQuery", SearchQuery.length);
  };

  useEffect(() => {
    onClick && showSuggestion();
    if (SearchQuery?.length == 0 || SearchQuery?.length < 1) {
      ApplyFilter();
      setinputWidth(180);
      setVisible(false);
    } else if (SearchQuery?.length > 5) {
      setinputWidth(100 + SearchQuery?.length * 6.6);
    }
  }, [SearchQuery]);

  useEffect(() => {
    const userSuggestion = getUserSuggestionArray(SearchUserData);
    const companySuggestion = !(filterManage === "talent")
      ? getCompanySuggetionArray(SearchUserData)
      : [];
    setSearchSuggestionsArr({ userSuggestion, companySuggestion });
  }, [SearchUserData]);

  const CampaginResult = (value) => {
    dispatch(fetchResultsCampaigns({ params: value, datePicker }));
  };

  // useEffect(() => {
  //     // dispatch(fetchcsvfile("hello"))
  // }, [campaignsSearch])
  console.log(SearchQuery);

  const footer = (
    <div className="Drawer-footer">
      <span onClick={ClearFilter}>Clear</span>
      <span onClick={ApplyFilter}>
        {window.innerWidth > 768 ? "Show Results" : "Apply Filter"}
      </span>
    </div>
  );

  const getHighlightedTextValue = (textValue, key) => {
    const highlight = SearchQuery;
    // Split on highlight term and include term into parts, ignore case (using regular expression)
    const parts = textValue?.split(new RegExp(`(${SearchQuery})`, "gi"));
    console.log("parts", parts);
    return (
      <span>
        {parts?.map((part, i) => (
          <span
            key={i}
            className={
              part?.toLowerCase() === SearchQuery?.toLowerCase()
                ? "Highlight_Seacrh_Value"
                : "NotLight_serach_Value"
            }
          >
            {part}
          </span>
        ))}
      </span>
    );
  };

  const rangepicker = (
    <>
      <span className="SearchHeader-Top-Colume-section-calender-range">
        <RangePicker
          disabledDate={(current) => {
            return current && current.valueOf() > Date.now();
          }}
          bordered={false}
          format="MMM D, YYYY"
          suffixIcon={false}
          className="Range-picker-color-change"
          defaultValue={[
            moment(startdate, dateFormat),
            moment(CurrentDate, dateFormat),
          ]}
        />
      </span>
    </>
  );

  const menu = data && (
    <Menu
      style={{ width: 250 }}
      onClick={({ key }) => {
        setDownloadValue(key);
      }}
      className="Search-header-Menu"
    >
      <Menu.Item
        key="Download this page"
        onClick={() =>
          alertNotification("success", "Reports downloaded successfully")
        }
      >
        <CSVLink
          filename={"Current_page_user.csv"}
          data={data}
          headers={CSVHeader}
        >
          Download this page
        </CSVLink>
      </Menu.Item>
      <Menu.Item onClick={() => handleAllDownloadCsv()} key="Download all">
        Download all
      </Menu.Item>
      {filterArr?.length > 0 ? (
        <Menu.Item
          key="Download selected"
          onClick={() =>
            alertNotification("success", "Reports downloaded successfully")
          }
        >
          <CSVLink
            filename={"Selected_user.csv"}
            data={filterArr}
            headers={CSVHeader}
          >
            Download selected
          </CSVLink>
        </Menu.Item>
      ) : null}
    </Menu>
  );

  const menuSearch = (
    <Menu
      style={window.innerWidth > 768 ? { width: 380 } : { width: 240 }}
      className="Search-header-Menu"
    >
      <div className="sbar-render-title1">
        <span>Candidates</span>
      </div>
      {Object.keys(SearchSuggestionsArr).length !== 0
        ? SearchSuggestionsArr?.userSuggestion.map((item, key) => {
            return (
              <>
                <Menu.Item
                  onClick={() => {
                    SelectNameFilter(item);
                    setOnClick(false);
                  }}
                  key={key}
                >
                  {getHighlightedTextValue(item)}
                </Menu.Item>
              </>
            );
          })
        : null}
    </Menu>
  );

  const menuSearchEmployer = (
    <Menu
      style={window.innerWidth > 768 ? { width: 380 } : { width: 240 }}
      className="Search-header-Menu"
    >
      <div className="sbar-render-title1">
        <span>Employer</span>
      </div>
      {Object.keys(SearchSuggestionsArr).length !== 0
        ? SearchSuggestionsArr?.userSuggestion?.map((item, key) => {
            return (
              <>
                <Menu.Item
                  onClick={() => {
                    SelectNameFilter(item);
                    setOnClick(false);
                  }}
                  key={key + 1}
                >
                  {getHighlightedTextValue(item)}
                </Menu.Item>
              </>
            );
          })
        : null}
      <div className="sbar-render-title1">
        <span>Company</span>
      </div>
      {Object.keys(SearchSuggestionsArr).length !== 0
        ? SearchSuggestionsArr?.companySuggestion?.map((item, key) => {
            return (
              <>
                <Menu.Item
                  onClick={() => {
                    SelectNameFilter(item);
                    setOnClick(false);
                  }}
                  key={item?._id}
                >
                  {getHighlightedTextValue(item)}
                </Menu.Item>
              </>
            );
          })
        : null}
    </Menu>
  );

  return (
    <div className="SearchHeaderConatiner">
      <Row>
        <Col className="SearchHeader-Top-Colume" span={24}>
          <div className="SearchHeader-Top-Colume-Section">
            <span className="SearchHeader-Top-Colume-Manage-Heading">
              {ManageCandidates} {ManageEmployer}
            </span>
            <div className="SearchHeader-Top-Colume-section-leftside">
              <div className="SearchHeader-Top-Colume-section-calender">
                <span>Last 30 days</span>
                {window.innerWidth > 768 && rangepicker}
              </div>
              {/* {ManageEmployer ? (
                <span className="SearchHeader-Top-Colume-section-Employer-Button">
                  <span>+</span>
                  <span>Add Employer</span>
                </span>
              ) : null} */}
            </div>
          </div>
        </Col>
        <Col className="SearchHeader-Filter-colume" span={24}>
          <div className="SearchHeader-Filter-section">
            <span className="SearchHeader-Filter-section-search">
              <Dropdown
                overlay={ManageEmployer ? menuSearchEmployer : menuSearch}
                visible={visible}
              >
                <Input
                  // className="dropCss"
                  style={window.innerWidth > 768 ? { width: inputwidth } : null}
                  value={SearchQuery}
                  allowClear={{
                    clearIcon: <CloseOutlined twoToneColor="#7e7e7e" />,
                  }}
                  placeholder="Search Anything..."
                  onChange={SearchQueryFunction}
                  onPressEnter={() => {
                    ApplyFilter(true);
                    setVisible(false);
                  }}
                  bordered={window.innerWidth > 768 ? false : true}
                  prefix={
                    window.innerWidth < 768 && (
                      <SearchOutlined
                        style={{ fontSize: "1rem", color: "#7e7e7e" }}
                      />
                    )
                  }
                />
              </Dropdown>
            </span>
            <div className="SearchHeader-Filter-section-left">
              <span
                onClick={() => {
                  countData?.length && setOpen(true);
                }}
              >
                {window.innerWidth > 768 ? (
                  <span>
                    Filter
                    {FilterCount > 0 && (
                      <span className="filterCount">({FilterCount})</span>
                    )}
                  </span>
                ) : (
                  <img src={FilterCount > 0 ? filterIconGreen : filterIcon} />
                )}
              </span>
              {/* <Dropdown overlay={menu} trigger={data && ["click"]}>
                {loadingCSV ? (
                  <Loader height={"0px"} size={"34px"} marginAutoLeft />
                ) : (
                  <span>
                    <img src={downloadIcon} alt="" />
                  </span>
                )}
              </Dropdown> */}
            </div>
          </div>
        </Col>
        <Col className="SearchHeader-Show-Results-section" span={24}>
          <span className="SearchHeader-Show-Results">
            Showing{" "}
            {loadingFC ? (
              <Loader height={"11px"} size={"11px"} display={"inline"} />
            ) : countData?.length ? (
              countData[0]?.TotalValue[0]?.count
            ) : (
              0
            )}{" "}
            results
          </span>
          {window.innerWidth < 768 && (
            <span>
              {!(isDateChange["onChangeDate"] && isDateChange["onApplyFilter"])
                ? "Last 30 days"
                : ""}
            </span>
          )}
        </Col>
      </Row>
      <Drawer
        title="Filter"
        placement={window.innerWidth > 768 ? "right" : "top"}
        visible={open}
        closable={true}
        trigger={["click"]}
        onClose={() => setOpen(false)}
        open={open}
        key={"left"}
        height="99.5%"
        footer={footer}
        className="search-header-Drower"
      >
        {window.innerWidth < 768 && (
          <span onClick={ClearFilter} className="search-mobile-clear-dropdown">
            Clear All
          </span>
        )}
        <SearchRadioButton
          SetterState={setProfession}
          ArrayObject={countData?.length ? countData[2].ProfessionCode : 0}
          HeadingText="Profession"
          ValueState={Profession}
        />
        <div className="search-header-Drower-content">
          {ManageEmployer ? (
            <SearchRadioButton
              SetterState={setStatus}
              ArrayObject={countData?.length ? countData[1].status : 0}
              HeadingText="Status"
              ValueState={Status}
            />
          ) : null}
          {ManageCandidates ? (
            <SearchRadioButton
              SetterState={setStatus}
              ArrayObject={countData?.length ? countData[1].status : 0}
              HeadingText="Status"
              ValueState={Status}
            />
          ) : null}
          {ManageCandidates ? (
            <SearchRadioButton
              SetterState={setAuthValue}
              ArrayObject={
                countData?.length ? countData[3].AuthenticationProvider : 0
              }
              HeadingText="Authentication"
              ValueState={AuthValue}
            />
          ) : null}
          {ManageCandidates ? (
            <SearchRadioButton
              SetterState={setSourceValue}
              ArrayObject={countData?.length ? countData[4].MCSource : 0}
              HeadingText="Source"
              ValueState={SourceValue}
            />
          ) : null}
          {ManageCandidates ? (
            <SearchRadioButtonCampaigns
              SetterState={setCampaignsvalue}
              ArrayObject={Searchcampaigns ? Searchcampaigns : Campaigns}
              HeadingText="Campaigns"
              ValueState={CampaignsValue}
              setcampaignsSearch={CampaginResult}
            />
          ) : null}
          {window.innerWidth < 768 ? (
            <span className="search-header-Drower-Profession Mobile-date-range">
              {"Date Range"}
              <RangePicker
                suffixIcon={<img src={DatePickerCalendra} alt="Calender" />}
                disabledDate={(current) => handlingDisableDate(current)}
                format="MMM D, YYYY"
                className="Range-picker-color-change"
                defaultValue={[
                  moment(startdate, dateFormat),
                  moment(CurrentDate, dateFormat),
                ]}
                onChange={(e) => handleDataPicker(e)}
              />
            </span>
          ) : null}
        </div>
      </Drawer>
    </div>
  );
};

export default SearchHeader;
