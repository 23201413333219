import React, { useState } from "react";
import TableWithPagination from "../../components/Table/TableWithPagination";
import SearchHeader from "../../components/SearchHeader/SearchHeader";

import CardPlusPagination from "./CardPlusPagination";
import SearchAndFilter from "../../components/SearchAndFilter/SearchAndFilter";

const ManageCandidate = () => {
  const [globelPage, setglobelpage] = useState({
    page: 1,
    limit: window.innerWidth > 768 ? 10 : 15,
  });
  const [filterArr, setfilterArr] = useState([]);

  const type = "talent";
  return (
    <>
      <div className="ManageCandidateContainer">
        {window.innerWidth > 768 ? (
          <SearchAndFilter
            type={type}
            filterArr={filterArr}
            handlingPagination={[globelPage, setglobelpage]}
          />
        ) : (
          <div>
            <SearchHeader
              ManageCandidates="Manage Candidate"
              handlingPagination={[globelPage, setglobelpage]}
              filterArr={filterArr}
            />
          </div>
        )}
        {window.innerWidth > 768 ? (
          <TableWithPagination
            type={type}
            handlingPagination={[globelPage, setglobelpage]}
            setfilterArr={setfilterArr}
          />
        ) : (
          <CardPlusPagination
            type={type}
            handlingPagination={[globelPage, setglobelpage]}
            setfilterArr={setfilterArr}
          />
        )}
      </div>
    </>
  );
};

export default ManageCandidate;
