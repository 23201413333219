import React, { useState } from "react";
import "./MobileNavMenu.css";
import menuIcon from "../../../assets/icons/ic-round-menu.svg";
import NavigationItem from "../components/NavigationItem/NavigationItem";
import { Drawer } from "antd";

const MobileNavMenu = () => {
  const userName = "Maniram Chauhan";
  const userRole = "Administrator";
  const [menuOpen, setMenuOpen] = useState(false);
  const showDrawer = () => {
    setMenuOpen(true);
  };
  const onClose = () => {
    setMenuOpen(false);
  };
  const title = (
    <>
      <div className="right_header">
        <div className="user_profile_pic">
          {/* <img src="" alt="" /> */}
          <span>{userName[0]}</span>
        </div>
        <div className="user_profile_info">
          <span className="user_profile_name">{userName}</span>
          <span className="user_profile_role">{userRole}</span>
        </div>
      </div>
    </>
  );
  return (
    <>
      <nav className="MobileNavMenu_container">
        <>
          <img src={menuIcon} alt="" onClick={showDrawer} />
          <Drawer
            // title={title}
            placement="left"
            closable={false}
            onClose={onClose}
            visible={menuOpen}
            width="20rem"
            className="MobileNavMenu_body"
          >
            <NavigationItem onClose={onClose} />
          </Drawer>
        </>
      </nav>
    </>
  );
};

export default MobileNavMenu;
