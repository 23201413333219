import React, { useEffect, useState } from "react";
import "./AdminLogin.css";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Tabs,
  Divider,
  Tooltip,
  Modal,
} from "antd";
import "antd/dist/antd.css";
import { setCookie } from "../../shared/helper";
import { Auth } from "../../Config/aws-exports";
import { GoAlert } from "react-icons/go";
import GreenCheck from "../../assets/icons/typcn_tick.svg";
import {
  UserOutlined,
  MailOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
  LoadingOutlined,
} from "@ant-design/icons";
import { VscLock } from "react-icons/vsc";
import { useHistory } from "react-router-dom";
import ForgotPassword from "./components/ForgotPassword";
import { useDispatch } from "react-redux";
import { fetchUserProfile } from "../../redux/actions";
import { onLogout } from "../../shared/helper";
import { alertNotification } from "../../components/notifications/alertNotification";
const { TabPane } = Tabs;
const AdminLogin = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [pascheck, setpascheck] = useState(null);
  const [check, setCheck] = useState(false);
  const [greenCheck, setGreenCheck] = useState(false);
  const [showCheckIconEmail, setShowCheckIconEmail] = useState(false);
  const [isremember, setIsRemember] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(null);
  const [errormessage, setErrorMessage] = useState("");
  const [count, setCount] = useState(false);
  const [messagetype, setMessageType] = useState("container-error");
  const [isModalVisibleAuth, setIsModalVisibleAuth] = useState(false);
  const [backClicked, setBackClicked] = useState(false);
  const [passcheck, setpasscheck] = useState(false);
  const clicked = () => {
    setCheck(!check);
    // console.log(check);
  };
  const getRememberMe = JSON.parse(localStorage.getItem("RememberMe"));
  let InitialValue = !getRememberMe ? false : getRememberMe;
  console.log("InitialValue", InitialValue);
  const history = useHistory();
  const GetEmail = localStorage.getItem("Email");
  console.log("GetEmail", GetEmail);
  const GetPassword = localStorage.getItem("password");
  console.log("GetEmail", GetPassword);
  const remember = (e) => {
    if (e.target.checked === true) {
      // setIsRemember(true);
      localStorage.setItem("RememberMe", true);
    } else if (e.target.checked === false) {
      // setIsRemember(false);
      localStorage.setItem("RememberMe", false);
    }
    setIsRemember(!isremember);
    console.log(isremember);
  };
  const textemail = <span>example: example@mail.com</span>;
  const textpass = (
    <span>
      Minimum 8 characters including uppercase, lowercase, symbol and digits.
    </span>
  );
  const buttonWidth = 70;
  const [mode, setMode] = useState("Login");
  const SwitchMode = (key) => {
    setMode(key);
  };
  const showModal = () => {
    // dispatch(isModalVisibleAuth(true));
    setIsModalVisibleAuth(true);
  };
  const modalCancel = () => {
    // dispatch(isModalVisibleAuth(false));
    setIsModalVisibleAuth(false);
    form.resetFields();
    setBackClicked(true);
  };
  const forgetpassword = () => {
    if (window.innerWidth < 768) {
      history.push("/forgot");
    } else {
      showModal();
    }
  };
  const lostPassToLogin = (data) => {
    // console.log("lostpass data", data);
    if (data === "Success") {
      setTimeout(() => {
        setIsModalVisibleAuth(false);
      }, 3000);
    }
  };
  // const onFinish = (values) => {
  //   console.log("Received values of form: ", values);
  // };
  const validatePassword = (e) => {
    const password = e.target.value;
    if (password.length === 0) {
      setPasswordValidation(false);
      setpascheck(password);
      console.log(password.length, "password.length");
    } else if (password.length > 7) {
      setpasscheck(true);
    } else if (password.length < 7) {
      setpasscheck(false);
    }
    // useEffect(()=>{
    //   if (pascheck.length < 7){
    //   setPasswordValidation(false);
    //   }
    // }, [pascheck])
    // const valid = /^\S{8,}$/;
    // console.log(password);
    // if (valid.test(password)) {
    //   setPasswordValidation(false);
    // } else {
    //   setPasswordValidation(true);
    // }
  };
  // useEffect(() => {
  //   setPasswordValidation(true);
  // },[])
  const validateEmail = (e) => {
    const email = e.target.value;
    const valid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.length > 0) {
      setShowCheckIconEmail(true);
    }
    if (valid.test(email)) {
      setGreenCheck(true);
    } else {
      setGreenCheck(false);
    }
  };
  const onLogin = async ({ email, password }) => {
    localStorage.setItem("Email", email);
    localStorage.setItem("password", password);
    setCount(false);
    setLoading(true);
    // console.log("Received values of form: ", values);
    try {
      const response = await Auth.signIn({ username: email, password });
      console.log(response);
      // https://serverless-idm.awssecworkshops.com/01-user-auth/#validate-sign-up-and-sign-in
      const session = await Auth.currentSession();
      // console.log('Cognito User Access Token:', session.getAccessToken().getJwtToken());
      // console.log(
      //   "Cognito User Identity Token:",
      //   session.getIdToken().getJwtToken()
      // );

      const admintoken = session.getIdToken().getJwtToken();

      const idToken = session.idToken.jwtToken;

      setCookie("adminToken", admintoken);
      setCookie("idTokenAdmin", idToken);

      // console.log("adminToken", admintoken);
      // console.log("adminToken:log", session.idToken.jwtToken);

      if (isremember) {
        // console.log("Clicked inside if");
        const result = await Auth.rememberDevice();
        console.log("Remember me: ", result);
        if (result == "SUCCESS") {
          setCookie("RememberMe", "true");
        }
      }
      // console.log('Cognito User Refresh Token', session.getRefreshToken().getToken());
      setLoading(false);
      // dispatch(fetchUserProfile(idToken));
      history.push("/manageEmployer");
      if (
        session?.accessToken?.payload?.["cognito:groups"] === undefined
        // session?.accessToken?.payload?.["cognito:groups"] == "P3-Admin-Role"
      ) {
        alertNotification("warning", "Sorry not an authorized Admin user");
        onLogout();
      }
    } catch (error) {
      setLoading(false);
      setCount(true);
      // message.error(error.message);
      setPasswordValidation(true);
      setTimeout(() => {
        setCount(false);
      }, 4000);
      console.log("error signing in", error);
      if (isremember) {
        console.log("Error remembering device", error);
      }
    }
  };
  return (
    <div className="join-pop-up">
      <div className="container-auth">
        {window.innerWidth < 768 ? (
          <div className="Sign-into-SkillGigs"> Sign In to SkillGigs</div>
        ) : (
          <div className="Sign-into-SkillGigs"> Sign into SkillGigs</div>
        )}
        {/* Tab1 */}
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: InitialValue,
            email: InitialValue === true ? GetEmail : "",
            password: InitialValue === true ? GetPassword : "",
          }}
          onFinish={onLogin}
          onLinkClick
        >
          <Tooltip placement="topRight" title={textemail}></Tooltip>
          <Form.Item
            name="email"
            hasFeedback
            validateStatus={loading ? "validating" : null}
            rules={[
              {
                type: "email",
                message: "email is not Valid!",
              },
              {
                required: true,
                message: "Please add your email address",
              },
            ]}
          >
            <Input
              // type="email"
              onChange={(e) => validateEmail(e)}
              className={`emailInputSignUp ${
                greenCheck && !passwordValidation
                  ? "validInputCSS"
                  : `${showCheckIconEmail ? "notValidInput" : null}`
              }`}
              loading={true}
              placeholder="Enter your email"
              id="validating"
              suffix={
                greenCheck && !passwordValidation ? (
                  <>
                    {!loading ? (
                      <img src={GreenCheck} alt="greenCheck" />
                    ) : null}
                  </>
                ) : (
                  <>
                    {showCheckIconEmail ? (
                      <>
                        {!loading ? (
                          <GoAlert
                            style={{
                              color: "#db2828",
                              marginRight: "0.4rem",
                            }}
                          />
                        ) : null}
                      </>
                    ) : null}
                  </>
                )
              }
            />
          </Form.Item>
          <Form.Item
            name="password"
            help={
              passwordValidation ? (
                <div className="notValidColor">
                  Incorrect username or password
                </div>
              ) : null
            }
            rules={[
              {
                pattern: /^\S{1,}$/,
                required: true,
                message: "Please enter your password",
              },
            ]}
          >
            <Input.Password
              // name="password"
              rules={[
                {
                  pattern: /^\S{8,}$/,
                  message: "please use a valid password",
                },
                {
                  required: true,
                },
              ]}
              onChange={(e) => validatePassword(e)}
              className={`passwordSignUp ${
                passwordValidation === true
                  ? "notValidInput"
                  : passcheck
                  ? "validInputCSS"
                  : null
              } `}
              placeholder="Password"
              iconRender={(visible) =>
                visible ? (
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: 500,
                      color: "#007cf0",
                      cursor: "pointer",
                    }}
                  >
                    Hide
                  </span>
                ) : (
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: 500,
                      color: "#007cf0",
                      cursor: "pointer",
                    }}
                  >
                    Show
                  </span>
                )
              }
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ fontSize: "1rem" }}
              onClick={() => {
                if (!greenCheck) {
                  setShowCheckIconEmail(true);
                }
              }}
            >
              Sign in
            </Button>
          </Form.Item>
          <Form.Item>
            <div className="rememberBox">
              <div>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox onChange={(e) => remember(e)}>
                    <div className="remeberForgotCSS">
                      <span>Remember me</span>
                    </div>
                  </Checkbox>
                </Form.Item>
              </div>
              <div className="ForgottPassword" onClick={forgetpassword}>
                {" "}
                Forgot Password?
              </div>
            </div>
          </Form.Item>
        </Form>
        <Modal
          bodyStyle={{ padding: 0 }}
          width="24.37rem"
          visible={isModalVisibleAuth}
          closable={false}
          footer={null}
          // style={{ top: 45, padding: 0 }}
          onCancel={modalCancel}
          maskClosable={true}
          centered={true}
          destroyOnClose={true}
          destroyPopupOnHide={true}
        >
          <ForgotPassword
            lostPassToLogin={lostPassToLogin}
            backClick={backClicked}
          />
          <div className="authDivider"></div>
          <div
            className="bottom-text"
            onClick={() => {
              // history.push("/app/login");
              {
                modalCancel();
              }
            }}
          >
            Back to Sign In
          </div>
        </Modal>
        {/* Tab2 */}
      </div>
    </div>
  );
};
export default AdminLogin;
